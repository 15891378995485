import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import storeHttp from '../utils/service/store'

const Home = () => import('../views/home/Home.vue')
const AccountManage = () => import('../views/accountManage/AccountManage.vue')
const UserInfo = () => import('../views/accountManage/UserInfo.vue')
const ResetPassword = () => import('../views/accountManage/ResetPassword.vue')
const StoreSet = () => import('../views/accountManage/StoreSet.vue')
const MobileOrderSet = () => import('../views/accountManage/MobileOrderSet.vue')
const InvoiceSet = () => import('../views/accountManage/InvoiceSet.vue')
const DeliveryPlatformSet = () => import('../views/accountManage/DeliveryPlatformSet.vue')
const StoreManage = () => import('../views/storeManage/StoreManage.vue')
const AddStore = () => import('../views/storeManage/AddStore.vue')
const MangerContract = () => import('../views/storeManage/MangerContract.vue')
const StoreDetails = () => import('../views/storeManage/StoreDetails.vue')
const BusinessManage = () =>
	import('../views/businessManage/BusinessManage.vue')
const AddBusiness = () => import('../views/businessManage/AddBusiness.vue')
const BusinessDetails = () =>
	import('../views/businessManage/BusinessDetails.vue')
const SystemSetting = () => import('@/views/system/SystemSetting.vue')
const MenuManage = () => import('../views/menuManage/MenuManage.vue')
const Category = () => import('../views/menuManage/category/Category.vue')
const Taste = () => import('../views/menuManage/taste/Taste.vue')
const Upgrade = () => import('@/views/menuManage/upgrade/Upgrade.vue')
const Meals = () => import('../views/menuManage/meal/Meals.vue')
const Editor = () => import('../views/menuManage/meal/Editor.vue')
const OrderManage = () => import('../views/orderManage/OrderManage.vue')
const OrderDetails = () => import('../views/report/OrderDetails.vue')
const Report = () => import('../views/report/Report.vue')
const OrderManageList = () => import('../views/report/OrderManageList.vue')
const CashDrawerRecord = () => import('../views/report/CashDrawerRecord.vue')
const OrderManageSocket = () =>
	import('../views/orderManage/OrderManageSocket.vue')
const StorePos = () => import('../views/pos/StorePos.vue')
const Login = () => import('../views/Login.vue')
const SalesRanking = () => import('../views/report/SalesRanking.vue')
const BusinessStatement = () => import('../views/report/BusinessStatement.vue')
const Remark = () => import('@/views/menuManage/remark/Remark')
const AnnouncementManager = () =>
	import('../views/announcement/AnnouncementManager')
const Announcement = () =>
	import('../views/announcement/announcement/Announcement')
const AnnouncementEditor = () =>
	import('@/views/announcement/announcement/AnnouncementEditor')
const AnnouncementTemplate = () =>
	import('../views/announcement/announcementTemplate/AnnouncementTemplate')
const AnnouncementTemplateEditor = () =>
	import('@/views/announcement/announcementTemplate/AnnouncementTemplateEditor')
const ContractClassification = () =>
	import('@/views/contractManage/ContractClassification')
const ContractManage = () => import('@/views/contractManage/ContractManage')
const MailHistory = () => import('@/views/mailHistory/MailHistory')
const AdminResetPassword = () =>
	import('@/views/accountManage/AdminResetPassword')
const WhatsEatManage = () => import('@/views/whatsEatManage/WhatsEatManage.vue')
const WhatsEatPanel = () => import('@/views/whatsEatManage/WhatsEatPanel.vue')

Vue.use(VueRouter)

export const adminRoutes = [
	{
		path: 'store-manage',
		name: 'StoreManage',
		icon: 'mdi-home-outline',
		title: '店家管理',
		component: StoreManage,
		meta: {
			roles: [
				'ROLE_SUPER_SPECIAL_MANAGER',
				'USER_MANAGER',
				'USER_SALES_PERSON',
			],
		},
		children: [
			{
				path: 'add-store',
				name: 'StoreManageAddStore',
				component: AddStore /* webpackChunkName: "StoreManageAddStore" */,
				hide: true,
				meta: {
					roles: [
						'ROLE_SUPER_SPECIAL_MANAGER',
						'USER_MANAGER',
						'USER_SALES_PERSON',
					],
				},
			},
			{
				path: 'add-contract/:storeId',
				name: 'StoreManageMangerContract',
				component:
					MangerContract /* webpackChunkName: "StoreManageMangerContract" */,
				hide: true,
				meta: {
					roles: [
						'ROLE_SUPER_SPECIAL_MANAGER',
						'USER_MANAGER',
						'USER_SALES_PERSON',
					],
				},
			},
			{
				path: 'store-details/:storeId/:tabType',
				name: 'StoreManageStoreDetails',
				component:
					StoreDetails /* webpackChunkName: "StoreManageStoreDetails" */,
				hide: true,
				meta: {
					roles: [
						'ROLE_SUPER_SPECIAL_MANAGER',
						'USER_MANAGER',
						'USER_SALES_PERSON',
					],
				},
			},
		],
	},
	{
		path: 'business-manage',
		name: 'BusinessManage',
		icon: 'mdi-account-tie',
		title: '業務管理',
		component: BusinessManage,
		meta: {
			roles: ['ROLE_SUPER_SPECIAL_MANAGER', 'USER_MANAGER'],
		},

		children: [
			{
				path: 'add-business',
				name: 'BusinessManageAddBusiness',
				component:
					AddBusiness /* webpackChunkName: "BusinessManageAddBusiness" */,
				hide: true,
				meta: {
					roles: ['ROLE_SUPER_SPECIAL_MANAGER', 'USER_MANAGER'],
				},
			},
			{
				path: 'business-details/:businessId/:detailsType',
				name: 'BusinessManageBusinessDetails',
				component:
					BusinessDetails /* webpackChunkName: "BusinessManageBusinessDetails" */,
				hide: true,
				meta: {
					roles: ['ROLE_SUPER_SPECIAL_MANAGER', 'USER_MANAGER'],
				},
			},
		],
	},
	{
		path: 'store-pos',
		name: 'StorePos',
		icon: 'mdi-alarm-panel',
		title: 'POS 系統',
		component: StorePos /* webpackChunkName: "StorePos" */,
		meta: {
			roles: ['USER_STORE'],
		},
	},
	{
		path: 'order-manage',
		name: 'OrderManage',
		icon: 'mdi-cart-outline',
		title: '訂單管理',
		component: OrderManage,
		redirect: '/order-manage/socket',
		meta: {
			roles: ['USER_STORE'],
		},
		children: [
			{
				path: 'socket',
				name: 'OrderManageSocket',
				component: OrderManageSocket,
				hide: true,
				meta: {
					roles: ['USER_STORE'],
				},
			},
		],
	},
	{
		path: 'report',
		name: 'Report',
		icon: 'mdi-chart-waterfall',
		title: '報表',
		redirect: '/report/order-list',
		component: Report /* webpackChunkName: "StorePos" */,
		meta: {
			roles: ['USER_STORE'],
		},
		children: [
			{
				path: 'order-list',
				name: 'OrderList',
				title: '營業額',
				component: OrderManageList,
				hide: true,
				meta: {
					roles: ['USER_STORE'],
				},
			},
			{
				path: 'cash-drawer-record',
				name: 'CashDrawerRecord',
				title: '收支',
				component: CashDrawerRecord,
				hide: true,
				meta: {
					roles: ['USER_STORE'],
				},
			},
			{
				path: 'business-statement',
				name: 'BusinessStatement',
				title: '交班',
				component: BusinessStatement,
				hide: true,
				meta: {
					roles: ['USER_STORE'],
				},
			},
			{
				path: 'sales-ranking',
				name: 'salesRanking',
				title: '銷售排行榜',
				component: SalesRanking,
				hide: true,
				meta: {
					roles: ['USER_STORE'],
				},
			},
			{
				path: 'order-details/:orderId',
				name: 'OrderManageOrderDetails',
				component:
					OrderDetails /* webpackChunkName: "OrderManageOrderDetails" */,
				hide: true,
				meta: {
					roles: ['USER_STORE'],
				},
			},
		],
	},
	{
		path: 'menu-manage',
		name: 'MenuManage',
		icon: 'mdi-silverware-variant',
		title: '菜單管理',
		component: MenuManage,
		redirect: '/menu-manage/meals',
		meta: {
			roles: ['USER_STORE'],
		},
		children: [
			{
				path: 'meals',
				name: 'MenuManageCategory',
				icon: 'mdi-account-box',
				title: '菜單管理',
				hide: true,
				component: Meals /* webpackChunkName: "MenuManageCategory" */,
				meta: {
					roles: ['USER_STORE'],
				},
			},
			{
				path: 'category',
				name: 'MenuCategory',
				component: Category,
				title: '分類管理',
				hide: true,
				meta: {
					roles: ['USER_STORE'],
				},
			},
			{
				path: 'taste',
				name: 'MenuManageTaste',
				icon: 'mdi-account-box',
				title: '口味管理',
				hide: true,
				component: Taste /* webpackChunkName: "MenuManageTaste" */,
				meta: {
					roles: ['USER_STORE'],
				},
			},
			{
				path: 'upgrade',
				name: 'Upgrade',
				icon: 'mdi-account-box',
				title: '升級管理',
				hide: true,
				component: Upgrade /* webpackChunkName: "MenuManageComboList" */,
				meta: {
					roles: ['USER_STORE'],
				},
			},

			{
				path: 'meal/editor',
				name: 'MenuMealEditor',
				component: Editor /* webpackChunkName: "MenuMealEditor" */,
				hide: true,
				meta: {
					roles: ['USER_STORE'],
				},
			},
			{
				path: 'remark',
				name: 'Remark',
				icon: 'mdi-account-box',
				title: '備註管理',
				hide: true,
				component: Remark /* webpackChunkName: "MenuManageCategory" */,
				meta: {
					roles: ['USER_STORE'],
				},
			},
		],
	},

	{
		path: 'contractManage',
		name: 'ContractManage',
		icon: 'mdi-file-sign',
		title: '合約管理',
		redirect: '/contractManage/contract-classification',
		component: ContractManage,
		meta: {
			roles: [
				'ROLE_SUPER_SPECIAL_MANAGER',
				'USER_MANAGER',
				'USER_SALES_PERSON',
			],
		},

		children: [
			{
				path: 'contract-classification',
				name: 'ContractClassification',
				icon: 'mdi-account-box',
				title: '合約分類',
				hide: true,
				component:
					ContractClassification /* webpackChunkName: "MenuManageCategory" */,
				meta: {
					roles: [
						'ROLE_SUPER_SPECIAL_MANAGER',
						'USER_MANAGER',
						'USER_SALES_PERSON',
					],
				},
			},
		],
	},

	{
		path: 'announcementManager',
		name: 'AnnouncementManager',
		icon: 'mdi-bullhorn-outline',
		title: '公告管理',
		redirect: '/announcementManager/announcement',
		component: AnnouncementManager,
		meta: {
			roles: ['ROLE_SUPER_SPECIAL_MANAGER', 'USER_MANAGER'],
		},

		children: [
			{
				path: 'announcement',
				name: 'Announcement',
				icon: 'mdi-bullhorn-outline',
				title: '公告設置',
				component: Announcement,
				hide: true,
				meta: {
					roles: ['ROLE_SUPER_SPECIAL_MANAGER', 'USER_MANAGER'],
				},

				children: [
					{
						path: 'add-announcement',
						name: 'AnnouncementEditor',
						title: '公告設置',
						component: AnnouncementEditor,
						hide: true,
						meta: {
							roles: ['ROLE_SUPER_SPECIAL_MANAGER', 'USER_MANAGER'],
						},
					},
				],
			},
			{
				path: 'announcementTemplate',
				name: 'AnnouncementTemplate',
				icon: 'mdi-bullhorn-outline',
				title: '公版管理',
				component: AnnouncementTemplate,
				hide: true,
				meta: {
					roles: ['ROLE_SUPER_SPECIAL_MANAGER', 'USER_MANAGER'],
				},

				children: [
					{
						path: 'add-announcementTemplate',
						name: 'AnnouncementTemplateEditor',
						title: '公版管理',
						component: AnnouncementTemplateEditor,
						hide: true,
						meta: {
							roles: ['ROLE_SUPER_SPECIAL_MANAGER', 'USER_MANAGER'],
						},
					},
				],
			},
		],
	},

	{
		path: 'mail-history',
		name: 'MailHistory',
		icon: 'mdi-email',
		title: '郵件紀錄',
		component: MailHistory,
		meta: {
			roles: [
				'ROLE_SUPER_SPECIAL_MANAGER',
				'USER_MANAGER',
				'USER_SALES_PERSON',
			],
		},
	},

	{
		path: 'account-manage',
		name: 'AccountManage',
		icon: 'mdi-account-box',
		title: '店家管理',
		component: AccountManage,
		redirect: '/account-manage/store-set',
		meta: {
			roles: ['USER_STORE'],
		},
		children: [
			{
				path: 'user-info',
				name: 'AccountManageUserInfo',
				icon: 'mdi-account-box',
				title: '店家管理',
				component: UserInfo,
				meta: {
					roles: ['USER_STORE'],
				},
				hide: true,
			},
			{
				path: 'reset-password',
				name: 'AccountManageResetPassword',
				component: ResetPassword,
				hide: true,
				meta: {
					roles: ['USER_STORE'],
				},
			},
			{
				path: 'store-set',
				name: 'AccountManageStoreSet',
				component: StoreSet,
        title: '店家設定',
				hide: true,
				meta: {
					roles: ['USER_STORE'],
				},
			},
      {
        path: 'mobile-order-set',
        name: 'AccountManageMobileOrderSet',
        component: MobileOrderSet,
        title: '手機點餐設定',
        hide: true,
        meta: {
          roles: ['USER_STORE'],
        },
      },
			{
				path: 'invoice-set',
				name: 'AccountManageInvoiceSet',
				component: InvoiceSet,
				title: '電子發票設定',
				hide: true,
				meta: {
					roles: ['USER_STORE'],
				},
			},
			{
				path: 'delivery-platform-set',
				name: 'AccountManageDeliveryPlatformSet',
				component: DeliveryPlatformSet,
				title: '外送平台設定',
				hide: true,
				meta: {
					roles: ['USER_STORE'],
				},
			},
		],
	},
	{
		path: 'reset-password',
		name: 'AdminResetPassword',
		icon: 'mdi-account-box',
		title: '修改密碼',
		component: AdminResetPassword,
		meta: {
			roles: [
				'ROLE_SUPER_SPECIAL_MANAGER',
				'USER_MANAGER',
				'USER_SALES_PERSON',
			],
		},
	},
	{
		path: 'system-setting',
		name: 'SystemSetting',
		icon: 'mdi-cog-outline',
		title: '系統設定 ',
		component: SystemSetting,
		meta: {
			roles: ['ROLE_SUPER_SPECIAL_MANAGER', 'USER_MANAGER'],
		},
	},
	{
		path: 'whats-eat-manage',
		name: 'WhatsEatManage',
		icon: 'mdi-account-box',
		title: '吃什麼優惠卷',
		component: WhatsEatManage,
		meta: {
			roles: ['USER_STORE'],
		},
		children: [
			{
				path: 'whats-eat-panel',
				name: 'WhatsEatPanel',
				icon: 'mdi-account-box',
				title: '',
				hide: true,
				component: WhatsEatPanel,
				meta: {
					roles: ['USER_STORE'],
				},
			},
		],
	},

]

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
		redirect: '/account-manage/user-info',
		children: [...adminRoutes],
		meta: {
			roles: ['ROLE_SUPER_SPECIAL_MANAGER', 'USER_MANAGER'],
		},
	},
	{
		path: '/login',
		name: 'Login',
		component: Login /* webpackChunkName: "Login" */,
	},
]

const router = new VueRouter({ routes, mode: 'history' })

router.beforeEach(async (to, from, next) => {
	if (store.state.storage.manager) {
		store.state.validManagerCodeDisplay = false
	} else if (store.state.storage.storeId) {
    if (!from.path.startsWith('/report') && to.path.startsWith('/report')) {
      storeHttp
        .getStoreSetting(store.state.storage.storeId)
        .then(res => {
          const isOpen = res.data.reportManagerCodeVerificationSwitch
          store.state.validManagerCodeDisplay = !!isOpen
        })
        .catch(err => {
          console.error(err)
          store.state.validManagerCodeDisplay = true
        })
    } else if (to.path.startsWith('/account-manage/delivery-platform-set') && to.query.hasOwnProperty('code')) {
      const code = to.query.code

      // 使用 window.location.protocol 获取当前页面的协议
      const currentProtocol = window.location.protocol;
      const currentPort = window.location.port;
      let currentDomainAndPort = currentProtocol + '//' + window.location.hostname
      if (currentPort) {
        currentDomainAndPort = currentDomainAndPort + ':' + currentPort
      }
      const redirectUri = currentDomainAndPort + '/account-manage/delivery-platform-set'
      console.log('redirectUri 參數：', redirectUri);

      const data = {
        storeId: store.state.storage.storeId,
        authorized: true,
        code: code,
        redirectUri: redirectUri,
      }

      storeHttp
        .getUberEatsStoreAuthorization(data)
        .then(res => {
          const { status, message} = res
          if (status) {
            store.commit('storage/setUberEatsStoreAuthorization', true)
            store.commit('showMessage', '授權成功')
          } else {
            console.log('授權失敗')
            store.commit('showMessage', { text: message, })
          }
        })
        .catch(err => {
          console.error(err)
          store.commit('showMessage', { text: err, })
        })
    }
	}

	// 清空菜單管理 分類暫存
	if (
		from.path.startsWith('/menu-manage') &&
		!to.path.startsWith('/menu-manage')
	) {
		store.state.menuCategoryId = 0
	}

	if (
		!from.path.startsWith('/order-manage') &&
		to.path.startsWith('/order-manage')
	) {
		store.state.orderManagerRedTag = false
	}

	if (to.path === '/login' || to.path.slice(0, 9) === '/Refresh/') {
		next()
		return
	}

	const roles =
		to.meta.roles?.length &&
		to.meta.roles.some(item => {
			return item === store.state.storage.switchRole
		})
	roles ? next() : store.state.storage.switchRole ? next('/') : next('/login')
})

export default router
